import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';

import Amplify from 'aws-amplify';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HttpInterceptorProviders } from './interceptors/interceptor.provider';
import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { HomeComponent } from './pages/home/home.component';

Amplify.configure({
  Auth: {
    userPoolId: "us-west-2_r7cZO84nE",
    region: "us-west-2",
    userPoolWebClientId: "1qqql6sholgp7qn3irlekbe1b9",
    mandatorySignIn: true,
    oauth: {
      domain: 'auth.skitterbytes.com',
      scope: ['openid', "email", "aws.cognito.signin.user.admin"],
      redirectSignIn: 'https://skitterbytes.com/dashboard',
      redirectSignOut: 'https://skitterbytes.com/signout',
      responseType: 'token' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
});

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatGridListModule,
    MatIconModule,
    MatToolbarModule
  ],
  providers: [ 
    HttpInterceptorProviders, 
    AuthGuard ],
  bootstrap: [AppComponent]
})
export class AppModule { }
