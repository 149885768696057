<mat-toolbar>
    <ng-template [ngIf]="isUserAuthenticated | async" [ngIfElse]="anonymousToolbar">
        <div style="background:black;color:white; padding: 2px 5px 2px 5px;margin-right:5px;font-weight:bold" class="title">SB</div>
        <span style="font-weight: bold">SKITTER BYTES</span>
        <span class="spacer"></span>
        <span style="padding: 0 25px 0 0" class="mat-body">Signed in as <u>{{email | async}}</u></span>
        <button mat-stroked-button (click)="onSignoutClick()">Sign out</button>        
    </ng-template>    
    <ng-template #anonymousToolbar>
        <span class="spacer"></span>
        <button mat-stroked-button (click)="onLoginClick()">Sign in</button>    
    </ng-template>
</mat-toolbar>
<router-outlet></router-outlet>