import { Component } from '@angular/core';
import { Auth } from 'aws-amplify';
import { Observable, from } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'Skitter Bytes';
  email = from(Auth.currentAuthenticatedUser().then(user => { 
    return user.username;
  })
  .catch(e => {
    console.log(e);
    return "";
  }));;

  isUserAuthenticated: Observable<boolean> = from(Auth.currentAuthenticatedUser().then(() => { 
    return true; 
  })
  .catch(e => {
    console.log(e);
    return false;
  }));;
  
  onLoginClick(): void {
    Auth.federatedSignIn();
  }

  onSignoutClick(): void {
    Auth.signOut({ global: true })
      .then(data => console.log(data))
      .catch(err => console.log(err));
  }
}
